.list__item:nth-child(2) {
  background: whitesmoke;
}

.list__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 62px;
  padding: 11px 17px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
}

.link-icon {
  color: rgba(0, 0, 0, 0.54);
}

.min-w-0 {
  min-width: 0;
}

.min-w-0-important {
  min-width: 0 !important;
}
