@import './theme';
@import './variables';
@import './shared';

@font-face {
  font-family: 'HelveticaNeueLTStd_Roman';
  src: url('assets/fonts/HelveticaNeueLTStd_Roman.eot?') format('eot'),
  url('assets/fonts/HelveticaNeueLTStd_Roman.woff') format('woff'),
  url('assets/fonts/HelveticaNeueLTStd_Roman.ttf') format('truetype');
}

/* Rules for sizing the icon. */
.material-icons.mat-18 {
  font-size: 18px;
}

.material-icons.mat-24 {
  font-size: 24px;
}

.material-icons.mat-36 {
  font-size: 36px;
}

.material-icons.mat-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.mat-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.mat-dark.mat-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.mat-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.mat-light.mat-inactive {
  color: rgba(255, 255, 255, 0.3);
}

body {
  margin: 0;
}

* {
  font-family: 'HelveticaNeueLTStd_Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

b {
  font-weight: bold;
}

button {
  padding: 5px 10px;
}

.filter-bar-fab-button {
  margin-left: 12px;
  background-color: black;
}

.action-btn {
  margin-bottom: 20px;
  margin-right: 10px;
}

.connection-state {
  padding-top: 10px;
}

.side-details-container {
  width: 1000px;
}

.tab-group {
  &.tab-group--grey .mat-tab-header {
    background-color: rgb(245, 245, 245);
  }

  mat-tab-body {
    height: 100%;
  }
}

.star {
  color: $star-color;
}

app-page-header {
  > {
    margin-right: 16px;
  }
}

mat-select:focus:not(.mat-select-disabled) .mat-select-arrow,
mat-select:focus:not(.mat-select-disabled) .mat-select-trigger {
  color: map-get($wwag-app-accent, A400) !important;
}

body {
  [mat-raised-button].mat-primary[disabled],
  [mat-mini-fab].mat-primary[disabled],
  [mat-fab].mat-primary[disabled] {
    background-color: map-get($wwag-app-primary, 500);
  }

}

.mat-list-item {
  width: 100%;
}

.ui-state-default {
  border: none;
  border-bottom: 1px solid #d6d6d6;
}

.ui-inputtext:focus {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid map-get($wwag-app-primary, 500);
}

.ui-inputtext:enabled:hover {
  border-color: map-get($wwag-app-primary, 500);
}

.ui-calendar {
  width: 100%;

  input {
    width: calc(100% - .5em);
  }
}

.ui-button,
.ui-widget .ui-button,
.ui-widget .ui-button:enabled:hover,
.ui-widget .ui-button:focus,
.ui-button:enabled:hover, .ui-button:focus {
  background: $page-sub-header-background !important;
  border: 1px solid $page-header-background !important;
}

.ui-state-default {
  border: none;
  border-bottom: 1px solid #d6d6d6;
}

.ui-inputtext:focus {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid map-get($wwag-app-primary, 500);
}

.ui-inputtext:enabled:hover {
  border-color: map-get($wwag-app-primary, 500);
}

.ui-calendar {
  width: 100%;

  input {
    width: calc(100% - .5em);
  }
}

.ui-selectbutton .ui-button.ui-state-active {
  background: #186ba0 !important;
}

button[color=primary] {
  * {
    color: white !important;
  }
}

.mat-button, .mat-icon-button, .mat-raised-button {
  color: black !important;
}

[mat-raised-button].mat-primary[disabled] {
  background: #d6d6d6 !important;
}

.mat-raised-button.mat-accent[disabled] {
  color: #d6d6d6 !important;
}

.validation-error {
  color: red;
}

// Hack for not aligned mat-select with mat-input

:root {
  touch-action: manipulation;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent {
  color: white !important;
}

.chip-padding {
  padding-left: 7px;
}

.bold-text {
  font-weight: bold;
}

.wished-arrival-date-label {
  margin-bottom: 0px;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.38);
}

.clickable {
  cursor: pointer;
}

.td-dialog-actions button span {
  text-transform: none;
}

.back-button {
  .mat-button-wrapper {
    display: flex;
  }
}

.td-dialog-actions {
  .mat-accent {
    background-color: #0b4f86;
  }
}

// this is a hack from and needs to remain until
// https://github.com/angular/material2/issues/4591 has been resolved
// you see the problem on the transaction details page that doesn't scale to
// 100% height
.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body {
  display: flex !important;
  flex-direction: column;
}

.mat-tab-body-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
